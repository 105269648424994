<template>
  <v-app>
    <v-app-bar app color="accent-4" dark>
      <v-container class="pa-0">
        <div class="d-flex justify-space-between align-center">
          <v-avatar size="56" tile>
            <img :src="require('@/assets/logo.jpg')">
          </v-avatar>
          <div>
            <v-btn icon @click="createForm">
              <v-icon>mdi-clipboard-plus-outline</v-icon>
            </v-btn>
            <v-btn icon @click="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AdminLayout',
  methods: {
    createForm() {
      this.$router.push({ name: 'FormDetails' })
    },
    logout() {
      this.$store.commit('logout')
    }
  },
  mounted() {
    if (location.pathname === '/') {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
<style lang="scss">

</style>
